import React from "react";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";

const Field = styled(TextField)({
  margin: "10px 0",
});

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

const LoginUI = (props:any) => {

  const { loading, email, bindEmail, password, bindPassword, handleSubmit } = props;

  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <h1 style={{ fontSize: "22px", fontWeight: 800 }}>
        {" "}
        Welcome, sign in.
      </h1>
      <TextField label="Email" {...bindEmail} type="email" style={{margin: "10px 0"}}/>
      <TextField label="Password" type="password" {...bindPassword} style={{margin: "10px 0"}}/>
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        disabled={loading}
      >
        {loading && <CircularProgress size={20} style={{ marginRight: 20 }} />}
        Login to your Account
      </Button>
    </form>
  );
};

export default LoginUI;
