import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Card from "@material-ui/core/Card";
import { Amplify, Auth } from "aws-amplify";
import Login from "./routes/Login";
import Dashboard from "./routes/Dashboard";
import Garden from "./routes/Garden";
import { COGNITO } from "./configs/aws";
import ProtectedRoute from "./ProtectedRoute";
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
  Auth: {
    identityPoolId: COGNITO.IDENTITY_POOL_ID,
    region: COGNITO.REGION,
    userPoolId: COGNITO.USER_POOL_ID,
    userPoolWebClientId: COGNITO.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "list",
        endpoint: "https://d1mw6as3nj.execute-api.us-east-1.amazonaws.com/prod/lists"
      }
    ]
  },
});


const App: React.FC = () => {
  return (
    <>
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton edge="start" color="inherit" aria-label="hello" sx={{ mr: 2 }} href={"https://www.joshbrun.com"}>
            <EmojiPeopleIcon fontSize="large"/>
          </IconButton>
          <Typography variant="h5" color="inherit" component="div" sx={{ flexGrow: 1 }}>
            joshbrun
          </Typography>
          <IconButton size="large" edge="start" color="inherit" aria-label="hello" sx={{ mr: 2 }} href={"https://www.linkedin.com/in/joshua-brundan"}>
            <LinkedInIcon fontSize="large"/>
          </IconButton>
          <IconButton size="large" edge="start" color="inherit" aria-label="hello" sx={{ mr: 2 }} href={"https://www.instagram.com/joshbrundan/"}>
            <InstagramIcon fontSize="large"/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Router>
          <Switch>
            <Route path="/signin">
              <Card raised={true} style={{ width: 500, margin: "100px auto", padding: "40px" }}>
                <Login />
              </Card>
            </Route>
            <Route path="/garden">
              <ProtectedRoute component={Garden} />
            </Route>
            <Route path="/">
              <ProtectedRoute component={Dashboard} />
            </Route>
          </Switch>
      </Router>
    </>
  );
};

export default App;
