import React from "react";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/core/styles";
import { useInput } from "./../utils/forms";
import { Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import LoginUI from "../components/LoginUI";
import { Toolbar, IconButton, Button, Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';

const Field = styled(TextField)({
  margin: "10px 0",
});

const DLink = styled(Link)({
  margin: "15px 0",
  textAlign: "right",
});

const Signup: React.FC = (props:any) => {
  const [loading, setLoading] = React.useState(false);
  const [firstTimeLogin, setFirstTimeLogin] = React.useState(false);
  const [partialUser, setPartialUser] = React.useState(null);

  const history = useHistory();

  const { value: email, bind: bindEmail } = useInput("");
  const { value: password, bind: bindPassword } = useInput("");


  const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    setLoading(true);

    try {
      var response = await Auth.signIn(email, password);
      if(response.challengeName === "NEW_PASSWORD_REQUIRED"){
        setPartialUser(response);
        setFirstTimeLogin(true);
      } else {
        history.push("/");
      }
    } catch (error: any) {
    }
    setLoading(false);
  };
  const oldLoginProps = { history, loading, setLoading, email, bindEmail, password, bindPassword, partialUser, handleSubmit };
  const loginProps = {loading, email, bindEmail, password, bindPassword, handleSubmit};
  if(firstTimeLogin){
    return (<p>Not implemented yet</p>);
  } else {
    
    return (
      
    <LoginUI {...loginProps}/>
    );
  }
};

export default Signup;
