import React from "react";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Auth } from "aws-amplify";
import LockIcon from "@material-ui/icons/Lock";
import { useHistory } from "react-router-dom";
import DashboardCard from "../components/DashboardCard";

const Garden: React.FC = () => {
  const history = useHistory();

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      history.push("/signin");
    } catch (error) {
    }
  };

  return (
    <>
      <LockIcon /> <p>This is a private (auth protected) page. </p>
      <p style={{ fontSize: 12, marginBottom: "20px" }}>
        Let's party in the garden{" "}
      </p>
     
      <Button variant="contained" color="default" onClick={handleLogout}>
        <ExitToAppIcon /> Logout
      </Button>
    </>
  );
};

export const GardenCard = () => {
  return DashboardCard("Garden", "Garden Management Made Fun.", "/garden");
}

export default Garden
