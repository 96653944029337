import React, { useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { Button, Card, CardActionArea, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { ExitToApp, LinkedIn, Lock } from '@mui/icons-material';
import Protected from '../components/Protected';
import {GardenCard} from './Garden';

const Dashboard: React.FC = () => {
  const history = useHistory();
  const [data, setData] = React.useState(null);
  const [errorData, setErrorData] = React.useState(null);

  const goToLinkedIn = () => {
    alert(data);
    alert(errorData);
  };

  const handleLogout = async () => {
    try {
      alert("hi");
      const sess = await Auth.currentSession();
      const idToken = sess.getAccessToken().getJwtToken();
      const header = {
          Authorization: idToken
      };
      const apiName = 'list';
      const path = '/';
      const myInit = {
          headers: header,
          response: true,  
      };

      const response = await API.get(apiName, path, myInit)
      console.log(response);
      alert(response);
      alert(response['body']);
      await Auth.signOut();
      history.push("/signin");
    } catch (error) {
      alert(error);
    }
  };

  const exampleCard = () => {
      return (
      <Card raised={true} style={{ width: "auto", maxWidth: 345, margin: "auto", padding: '40px' }}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Lists
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Everyone loves lists
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            color="primary"
            style={{ marginRight: "20px" }}
            onClick={goToLinkedIn}
          >
            <LinkedIn />
          </Button>
          <Button  onClick={handleLogout}>
            <ExitToApp /> Logout
          </Button>
        </CardActions>
      </Card>
    );
  };

  const exampleProtectedCard = () => {
    return (
      <Protected component={GardenCard}/>    );

  }

  return (
    <>
      <Grid
          style={{ padding: 50 }}
          wrap={'wrap'}
          container
          spacing={2}
        >
        {exampleProtectedCard()}
        <Grid item>
          {exampleCard()}
        </Grid>
        {exampleProtectedCard()}
        <Grid item>
          {exampleCard()}
        </Grid>
        {exampleProtectedCard()}
        <Grid item>
          {exampleCard()}
        </Grid>
        {exampleProtectedCard()}
        {exampleProtectedCard()}
        {exampleProtectedCard()}
        <Grid item>
          {exampleCard()}
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
