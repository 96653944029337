import React from "react";
import { Button, Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

const DashboardCard = (title:string, description:string, route:string) => {
    const history = useHistory();
    const navigateToRoute = () => {
        console.log(route)
        history.push(route);
    };
  return (
        <Grid item>
            <Card raised={true} style={{ width: "auto", maxWidth: 345, margin: "auto", padding: '40px' }}>
                <CardActionArea onClick={navigateToRoute}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    {description}
                    </Typography>
                </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default DashboardCard;